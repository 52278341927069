import aaron from "../assets/img/aaron.jpg";
import yuantai from "../assets/img/yuantai.jpg";
import ethan from "../assets/img/EthanHubbartt.jpg";
import chalsea from "../assets/img/chalsea.jpg";
import hudson from "../assets/img/hudsonheadshot.jpg"
export const teamMembers = [
  {
    name: "Chalsea Chen",
    info: "Junior at UW Seattle majoring in Geography: Data Science",
    img: chalsea,
  },
  {
    name: "Ethan Hubbartt",
    info: "Junior at UW seattle majoring in Geography: Data Science",
    img: ethan,
  },
  {
    name: "Yuantai Li",
    info: "Junior at UW Seattle in Geography : Data Science major",
    img: yuantai,
  },
  {
    name: "Hudson Potts",
    info: "Junior at UW Seattle in Geography : Data Science major",
    img: hudson,
  },
  {
    name: "Aaron Thai",
    info: "Junior at UW Seattle majoring in Geography: Data Science",
    img: aaron,
  },
];
