export const styles = [
  {
    name: "Dark",
    uri: "mapbox://styles/mapbox/dark-v10",
  },
  {
    name: "Light",
    uri: "mapbox://styles/mapbox/light-v10",
  },
  {
    name: "Street",
    uri: "mapbox://styles/mapbox/streets-v11",
  },
];
