export const mapData = [
  {
    name: "World Cup Locations",
  },
  {
    name: "Country Data",
  },
  {
    name: "Player Data"
  }
];
